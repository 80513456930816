import "./css/bannerSection.css"

const BannerSection = ()=>{
    return(
        <div className="bannerSection">
            <div className="nameHeader">
                <h1 className="text-align-center">Prasanth KJ</h1>
            </div>
            <div className="bannerContent text-align-center">
                <h3 className="h4-onlyMobile">
                    Hey, Thanks for checking my website. <br/><br/>
                    Currently my website is under construction!..
                </h3>
            </div>
        </div>
    )
}

export default BannerSection;